<!-- Platform for uniliver -->
<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <div class="container-fluid">
          <ul class="breadcrumb ProximaNovaRegular" style="margin-top: 12px">
            <li>
              Solutions
            </li>
            <li>
              India
            </li>
            <!-- <li v-if="activeFrom === 1" :class="activeFrom === 1 ? 'ProximaNovaBold' : ''">
              DataSource
            </li>
            <li v-else>
              <span @click="activeFrom = 1">DataSource</span>
            </li>
            <li :class="activeFrom === 2 ? 'ProximaNovaBold' : ''" v-if="activeFrom === 2">
              Input Data Summary
            </li> -->
            <!-- <li v-else-if="activeFrom === 3">
              <span @click="activeFrom = 2">Input Data Summary</span>
            </li> -->
            <li :class="activeFrom === 3 ? 'ProximaNovaBold' : ''" v-if="activeFrom === 3">
              KPI
            </li>
          </ul>
        </div>

        <div class="container mb-5" style="margin-top: 28px">
          <div class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center
            ">
            <div class="col-sm-12 col-md-7 col-xs-12 col-lg-7">
              <w-multi-step-bar :stepName="stepName" :activeState="activeFrom"></w-multi-step-bar>
            </div>
          </div>
        </div>
        <div class="container-fluid" style="margin-top: 80px; padding-left: 52px" v-if="activeFrom === 1">
          <w-tab :tabName="activeTab" :tabs="categorizedSelectedConnectors" @activeTab="changeTab($event)">
          </w-tab>
        </div>
        <div class="w-100" v-if="activeFrom === 1">
          <div class="container-fluid tab-panel" style="margin-top: 36px; padding-left: 52px"
            v-for="(tab, index) in categorizedSelectedConnectors" :key="index"
            :class="{ showConnectors: activeTab === tab.category }">
            <div class="card w-100" v-for="(tabContent, index) in tab.tabContent" :key="index">
              <div class="card-header font-large ProximaNovaBold" style="
                padding: 0.75rem 1.5rem;
                background: transparent;
                border: 0px !important;
              ">
                {{ tabContent.title }}
              </div>
              <div class="card-body">
                <div>
                  <w-connector :addActiveClass="true" :connectors="tabContent.connectors" :title="tabContent.title"
                    :selectedConnectorsList="selectedConnectors">
                  </w-connector>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100" v-if="activeFrom === 2">
          <InputDataSummary />
        </div>
        <div class="w-100 py-4" v-if="activeFrom === 3">
          <div class="container-fluid" style="margin-top: 36px; padding-left: 52px;">
            <div class="col-md-12 col-lg-12">
              <div class="card w-100">
                <div class="row">
                  <div class="col-md-4 colo-lg-4">
                    <div class="w-100 p-5 m-5">
                      <img src="@/assets/Icons/kpi.webp" alt="KPI" height="100%" />
                    </div>
                  </div>
                  <div class="col-md-8 col-lg-8">
                    <div class="w-100 row " style="height: 100%">
                      <div class="w-100 d-flex px-5" style="
                          height: 15%;
                          justify-content: start;
                          align-items: flex-end;
                        ">
                        <span class="ProximaNovaBold" style="font-size: x-large">Define KPI</span>
                      </div>
                      <div class="col-md-6 colo-lg-6 d-flex justify-center">
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown :options="clientDropdown" :selectedOption="selectedClient" :placeHolder="'Select'"
                            :labelText="'Client'" class="mr-3"></w-dropdown>
                        </div>
                      </div>

                      <div class="col-md-6 colo-lg-6 d-flex justify-center">
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown :options="kpiSource" :selectedOption="selectedKPISource" :labelText="'Brand'"
                            class="mr-5" @input="getSelectedKpiSource($event)"></w-dropdown>
                        </div>
                      </div>




                      <div class="col-md-6 colo-lg-6 d-flex justify-center">
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown :options="marketDropdown" :labelText="'Country'" :selectedOption="selectedMarket"
                            class="mr-5" @input="selectMarket($event)"></w-dropdown>
                        </div>
                      </div>

                      <div class="col-md-6 colo-lg-6 d-flex justify-center">
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown :options="platformDropdown" :selectedOption="selectedPlatform"
                            :labelText="dropdownLabel" class="mr-5" @input="selectPlatform($event)"></w-dropdown>
                        </div>
                      </div>

                      <div class="col-md-6 colo-lg-6 d-flex justify-center">
                        <div v-if="skodaClient" class="w-100 p-4 pr-5">
                          <w-dropdown :options="roiMeasurmentDropdown" :selectedOption="selectedRoiMesaurment"
                            :labelText="'Range For ROI'" class="mr-5" @input="selectROiMeasurment($event)"></w-dropdown>
                        </div>
                      </div>
                      <div class="col-md-6 colo-lg-6 d-flex justify-center">
                        <div v-if="skodaClient" class="w-100 p-4 pr-5">
                          <w-dropdown :options="kpidropdown" :placeHolder="'Select'" :labelText="'KPI'"
                            :selectedOption="selectedKPI" class="mr-3" @input="selectedKPi($event)"></w-dropdown>
                        </div>
                      </div>
                      <div class="col-md-6 colo-lg-6 d-flex justify-center d-none">
                        <div class="w-100 m-4 mr-5 d-none" style="height: 60px !important">
                          <div class="p-0 d-flex multidropdownLabel" style="
                              align-items: center;
                              font-size: large;
                              width: 40%;
                            ">
                            <span class="pl-3 w-100">Input Start Date</span>
                            <div class="line"></div>
                          </div>
                          <DatePicker type="month" v-model="customDate" style="
                              width: 60% !important;
                              height: 60px !important;
                            " ref="datePicker" format="MMM YYYY" />
                        </div>
                      </div>
                      <div class="w-100 pr-5" style="height: 25%">
                        <div class="row mr-2 pr-2" style="float: right;" :class="{ changeColor: isDisable }">
                          <w-button :buttonLoader="'normal'" :buttonText="'Create Plan'"
                            :class="{ resetPasswordLink: isDisable }" :isDisable="isDisable"
                            @buttonClicked="createPlan">
                          </w-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-left: 52px" v-if="activeFrom === 2" class="d-none">
          <span style="font-size: 30px; cursor: pointer"><i class="far fa-plus-square" v-if="!showProcess"></i><i
              class="far fa-minus-square" v-else></i><span style="font-size: smaller">Process</span></span>
        </div>
        <div class="container-fluid w-100 d-flex" v-if="activeFrom < 3">
          <!--<w-button
              v-if="activeFrom === 2"
              class=" mr-2"
              style="width:auto;"
              :buttonLoader="'normal'"
              :buttonText="'Process'"
              @buttonClicked="showProcess = !showProcess"
            >
            </w-button>-->
          <div class="row pt-5 pb-5 mr-2 w-100 d-flex" style="justify-content: end;">
            <w-button style="width:auto;" :buttonLoader="'normal'" :buttonText="'Continue'"
              @buttonClicked="nextToSource">
            </w-button>
          </div>
        </div>

        <div class="container-fluid w-100" v-if="activeFrom === 2 && showProcess">
          <div class="mx-5 py-5">
            <div class="font-medium" v-for="(text, i) in processStep" :key="i">
              <span class="ProximaNovaBold">{{ i + 1 }}.</span>
              <span class="ProximaNovaSemiBold">{{ text }}</span>
            </div>
          </div>
        </div>
        <!--<div class="w-100 d-flex justify-center align-center">
            <Learning />
        </div>-->
        <solution-modal ref="solutionModal" @progressDone="doneProgress" />
      </main>
    </div>
    <page-loader v-if="isLoading" />
  </div>
</template>
<script>
import Connector from "@/components/AIMO-AI/Connector.vue";
import MultiStepProgressBar from "@/components/Solutions/MultiStepProgressBar.vue";
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/Tab.vue";
import Dropdown from "@/widgets/Dropdown.vue";
import PageLoader from "@/widgets/PageLoader.vue";

// import Learning from "@/components/AIMO-AI/Learning.vue"
import SolutionModal from "@/components/Modal/SolutionModal.vue";
import InputDataSummary from "./InputDataSummary.vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";

import { AimoDatapoemServices } from "@/services/AimoDatapoemServices.js";
const aimoDatapoemService = new AimoDatapoemServices();


export default {
  props: [],
  components: {
    "w-connector": Connector,
    DatePicker,
    "w-multi-step-bar": MultiStepProgressBar,
    "w-button": Button,
    "w-tab": Tab,
    "w-dropdown": Dropdown,
    "solution-modal": SolutionModal,
    "page-loader": PageLoader,
    InputDataSummary,
  },
  watch: {
    customDate(newVal) {
      if (newVal) {
        this.startDate = moment(newVal).format("YYYY-MM");
      }
    },
  },
  data() {
    return {
      skodaClient: false,
      isLoading: false,
      dropdownLabel: "",
      showProcess: false,
      processStep: ["Test", "Test", "Test", "Test", "Test"],
      kpiSource: [

      ],
      selectedKPISource: {},
      clientDropdown: [],
      customDate: new Date(
        "Tue Jan 01 2020 00:00:00 GMT+0530 (India Standard Time)"
      ),
      startDate: new Date(
        "Tue Jan 01 2020 00:00:00 GMT+0530 (India Standard Time)"
      ),
      selectedClient: {},
      kpidropdown: [
        { text: "Sales", id: "sales" },
        { text: "Leads", id: "leads", disable: true },
        { text: "Profit Margin", id: "Profit Margin", disable: true },
        { text: "Volume", id: "volume", disable: true },
        { text: "Cart Value", id: "Cart Value", disable: true },
      ],
      marketDropdown: [
        // { text: "USA", id: "allusa" },
        // { text: "HSM", id: "hsm", disable: true },
        // { text: "AP", id: "AP", disable: true },
        // { text: "TN", id: "TN", disable: true },
        // { text: "KER", id: "ker", disable: true },
        // { text: "KA", id: "KA", disable: true },
      ],
      platformDropdown: [

      ],
      selectedMarket: {},
      selectedPlatform: {},
      selectedKPI: { text: "Sales", id: "sales" },
      activeTab: "Digital Media",
      activeFrom: 3,
      orderSubCategory: [],
      selectedConnectors: [],
      selectedRoiMesaurment: { text: "Two Years", id: "12" },
      categorizedSelectedConnectors: [
        {
          category: "Digital Media",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google" },
                { name: "Facebook", image: "facebook" },
                { name: "Instagram", image: "instagram" },
              ],
            },
          ],
        },
        {
          category: "Main Line Media",
          tabContent: [
            {
              title: "TV",
              connectors: [{ name: "Barc", image: "Barc" }],
            },
            {
              title: "Print",
              connectors: [{ name: "MAP", image: "map" }],
            },
            {
              title: "Radio",
              connectors: [{ name: "MAP", image: "map" }],
            },
            {
              title: "Outdoor",
              connectors: [{ name: "Times OOH", image: "timesooh" }],
            },
          ],
        },
        {
          category: "Sponsorship",
          tabContent: [
            {
              title: "TV Live Cricket",
              connectors: [{ name: "Barc", image: "Barc" }],
            },
          ],
        },
        {
          category: "Competition Data",
          tabContent: [
            {
              title: "TV",
              connectors: [{ name: "Barc", image: "Barc" }],
            },
            {
              title: "Print",
              connectors: [{ name: "MAP", image: "map" }],
            },
            {
              title: "Radio",
              connectors: [{ name: "MAP", image: "map" }],
            },
            {
              title: "Trends",
              connectors: [{ name: "Google Trends", image: "Google Trends" }],
            },
            {
              title: "Outdoor",
              connectors: [{ name: "Times OOH", image: "timesooh" }],
            },
          ],
        },
        {
          category: "Additional Sources",
          tabContent: [
            {
              title: "Additional Sources",
              connectors: [
                { name: "New Product Launches", image: "" },
                { name: "New Store Launches", image: "" },
              ],
            },
          ],
        },
        {
          category: "External Data Sets",
          tabContent: [
            {
              title: "External Data Sets",
              connectors: [
                { name: "Holiday Calendar", image: "" },
                { name: "Cricket Calendar", image: "" },
                { name: "Covid Data", image: "" },
              ],
            },
          ],
        },
        {
          category: "CRM",
          tabContent: [
            {
              title: "CRM",
              connectors: [
                { name: "Mo-engage", image: "" },
                { name: "Manthan", image: "" },
              ],
            },
          ],
        },
        {
          category: "Sales Data",
          tabContent: [
            {
              title: "Sales Data",
              connectors: [
                { name: "Brand Sales Data", image: "" },
                { name: "Promotions details", image: "" },
              ],
            },
          ],
        },
        {
          category: "Organic Social",
          tabContent: [
            {
              title: "Organic Social",
              connectors: [
                { name: "Facebook", image: "Facebook" },
                { name: "Twitter", image: "twitter" },
                { name: "Instagram", image: "Instagram" },
                { name: "Youtube", image: "youtube" },
              ],
            },
          ],
        },
        {
          category: "Affiliates",
          tabContent: [
            {
              title: "Affiliates",
              connectors: [
                { name: "App Aff (Android)", image: "Affiliates" },
                { name: "App Partnerships", image: "Affiliates" },
                { name: "App Aff (IOS)", image: "Affiliates" },
                { name: "OEM", image: "Affiliates" },
                { name: "Web Partnerships", image: "Affiliates" },
                { name: "Web Affiliates", image: "Affiliates" },
                { name: "Scratch Card", image: "Affiliates" },
              ],
            },
          ],
        },
        {
          category: "Aggregators",
          tabContent: [
            {
              title: "Aggregators",
              connectors: [
                { name: "Zomato", image: "zomato" },
                { name: "Swiggy", image: "swiggy" },
              ],
            },
          ],
        },
      ],
      stepName: ["DataSource", "Input Data Summary", "KPI"],
      roiMeasurmentDropdown: [{ text: "Two Years", id: "12" }],
    };
  },
  computed: {
    isDisable() {
      if (
        this.selectedMarket !== "Select" &&
        this.selectedKPI !== "Select" &&
        this.selectedMarket !== "" &&
        this.selectedKPI !== "" &&
        this.checkObj(this.selectedKPI) !== 0 &&
        this.checkObj(this.selectedMarket) !== 0
      ) {
        return false;
      }
      return true;
    },
    checkObj() {
      return (data) => {
        return Object.keys(data).length;
      };
    },
  },
  methods: {
    checkClientAccess() {
      if (sessionStorage.getItem("subId") === "mivdlbztjvguuzwdruiy") {
        this.skodaClient = true

      } else {
        this.skodaClient = false

      }
    },
    saveToLocal() {
      console.log(this.selectedKPISource.id, this.selectedPlatform.id)
      this.selectedKPISource.id
      this.selectedPlatform.id
      localStorage.setItem("selectedKPISource", `${this.selectedKPISource.id}_${this.selectedPlatform.id}`)
      localStorage.setItem("selectedBrandKPI", this.selectedKPISource.text)
      localStorage.setItem("selectedMediaKPI", this.selectedPlatform.id)
    },
    selectROiMeasurment(e) {
      this.selectedRoiMesaurment = e;
    },
    getSelectedKpiSource(e) {
      console.log("r", this.selectedKPISource)
      this.selectedKPISource = e;
      this.saveToLocal()
    },
    doneProgress() {
      this.$router.push("/solutions/ul/platform/allstats");
    },
    createPlan() {
      // this.$refs.solutionModal.open();
      sessionStorage.setItem(
        "solutionsKpiMarket",
        JSON.stringify(this.selectedMarket)
      );
      this.saveToLocal()
      this.$router.push("/solutions/dp/platform/allstats");
    },
    selectedKPi(e) {
      console.log("e", e)
      if (this.selectedKPI != e) {
        this.selectedKPI = e;
        this.saveToLocal()
      }
    },
    selectMarket(e) {
      if (e === this.selectedMarket || e === "Select") {
        return;
      }
      sessionStorage.setItem("solutionsKpiMarket", JSON.stringify(e));
      this.selectedMarket = e;
    },
    selectPlatform(e) {
      if (e === this.selectedPlatform || e === "Select") {
        return;
      }
      console.log("d", e)

      this.selectedPlatform = e;
      this.saveToLocal()
    },
    orderSubCategoryMethod() {
      for (var i = 0; i < this.categorizedSelectedConnectors.length; i++) {
        var tabContent = [];
        for (
          var j = 0;
          j < this.categorizedSelectedConnectors[i].tabContent.length;
          j++
        ) {
          if (
            tabContent.find(
              (subTitle) =>
                subTitle.title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
            ) === undefined
          ) {
            tabContent.push({
              title: this.categorizedSelectedConnectors[i].tabContent[j].title,
              connectors: [
                {
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image:
                    this.categorizedSelectedConnectors[i].tabContent[j]
                      .className,
                },
              ],
            });
          } else {
            for (var k = 0; k < tabContent.length; k++) {
              if (
                tabContent[k].title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
              ) {
                tabContent[k].connectors.push({
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image:
                    this.categorizedSelectedConnectors[i].tabContent[j]
                      .className,
                });
              }
            }
          }
          if (
            j ===
            this.categorizedSelectedConnectors[i].tabContent.length - 1
          ) {
            this.categorizedSelectedConnectors[i].tabContent = tabContent;
          }
        }
        if (i === this.categorizedSelectedConnectors.length - 1) {
          this.activeTab = this.categorizedSelectedConnectors[0].category;
        }
      }
    },
    nextToSource() {
      if (this.activeFrom === 2) {
        this.activeFrom = 3;

        return;
      }
      this.activeFrom = 2;
      this.categorizedSelectedConnectors = [];
      for (var i = 0; i < this.selectedConnectors.length; i++) {
        if (
          this.categorizedSelectedConnectors.find(
            (obj) => obj.category === this.selectedConnectors[i].category
          ) === undefined
        ) {
          this.categorizedSelectedConnectors.push({
            category: this.selectedConnectors[i].category,
            tabContent: [this.selectedConnectors[i]],
          });
        } else {
          for (var j = 0; j < this.categorizedSelectedConnectors.length; j++) {
            if (
              this.categorizedSelectedConnectors[j].category ===
              this.selectedConnectors[i].category
            ) {
              this.categorizedSelectedConnectors[j].tabContent.push(
                this.selectedConnectors[i]
              );
            }
          }
        }
        if (i === this.selectedConnectors.length - 1) {
          this.orderSubCategoryMethod();
        }
      }
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    addActiveTab() { },
    addPlatform(obj) {
      var platformobj = obj.obj;
      var title = obj.title;
      if (
        this.selectedConnectors.find(
          (connector) => connector.role === platformobj.role
        ) != undefined
      ) {
        for (var i = 0; i < this.selectedConnectors.length; i++) {
          if (this.selectedConnectors[i].role == platformobj.role) {
            this.selectedConnectors.splice(i, 1);
          }
        }
      } else {
        this.selectedConnectors.push({
          title: title,
          category: this.activeTab,
          connectorName: platformobj.name,
          className: platformobj.image,
          role: platformobj.role,
        });
      }
    },
    getBrands(cliendId) {
      this.isLoading = true
      this.kpiSource = []
      this.platformDropdown = []
      aimoDatapoemService.getClientBrandDetails(cliendId).then((res) => {
        let dropdown_labels = Object.keys(res[0])
        this.dropdownLabel = dropdown_labels[1]

        let brand = res[0].Brand
        let kpi = res[0][this.dropdownLabel]

        for (var brands = 0; brands < brand.length; brands++) {
          this.kpiSource.push({ id: brand[brands], text: brand[brands] })
        }

        for (var kpis = 0; kpis < kpi.length; kpis++) {
          this.platformDropdown.push({ id: kpi[kpis], text: kpi[kpis] })
        }

        this.selectedKPISource = this.kpiSource[0]
        this.selectedPlatform = this.platformDropdown[0]
        this.isLoading = false
      }).catch((err) => {
        this.isLoading = false

        alert(err)
      })
    },
    getUserDetails() {
      this.marketDropdown = []

      let country = sessionStorage.getItem("country")
      let clientId = sessionStorage.getItem("subId")

      //push data
      if (country !== null) {
        this.marketDropdown.push({ id: country, text: country })
        this.selectedMarket = { id: country, text: country }
      } else {
        this.marketDropdown.push({ id: "NA", text: "NA" })
        this.selectedMarket = { id: "NA", text: "NA" }
      }

      //get brands
      this.getBrands(clientId)


    }
  },
  created() {
    this.clientDropdown = [];
    this.saveToLocal()
    this.clientDropdown.push({
      id: sessionStorage.getItem("subId"),
      text: sessionStorage.getItem("organization"),
    });
    this.selectedClient = this.clientDropdown[0];
    this.getUserDetails()
  },
};
</script>
<style scoped>
.changeColor .disableButton {
  color: #fff !important;
}

.multidropdownLabel {
  background: #eff5ff;
  color: #313846;
}

.line {
  height: 50%;
  border-right: 2px solid #c6ccd6;
}

.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}

.action {
  color: #000;
}

.container {
  height: 100%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.step-indicator .stepactive::after {
  background: #050505;
}

.tab-panel.showConnectors {
  display: block;
}

.tab-panel {
  display: none;
}

.card {
  display: block;
}
</style>
